import { Injectable } from '@angular/core';
import { ServerService } from '../server.service';
// import { ServerService } from '../services/server.service';
import { Inmobiliaria, RealEstatePhones, Meet } from '../../../modules/admin/inmobiliarias/inmobiliaria.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private _server: ServerService, 
    private http: HttpClient) { }

  registerRealEstate(realEstate: Inmobiliaria): Promise<Inmobiliaria> {
    return new Promise((resolve, reject) => {
      this._server.postServer('Register', realEstate).then(
        (resp: Inmobiliaria) => {

          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  getStates(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('states').then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getMeets(): Promise<Meet[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('Meet').then(
        (resp: Meet[]) => {
          resolve(resp);
        }, (err: any) => {
          reject();
        }
      );
    });
  }

  getProvinceById(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer('provinces/' + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  /* recoverPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer('reset_password', { email: email }).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  } */
  recoverPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL + 'reset_password', { email: email }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        }
      );
    });
  }
  


  realEstateSubscriptions(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer('getSubscriptionsRealEstate/' + id).then(
        (resp: any[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  fastRegister(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer('fast-Register', data).then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }
}
